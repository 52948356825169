import { $first } from './selectors';

// Setup event handlers for hiding menus
export const hideNavTree = () => {
  $first('.toolsResourceColumnVisible')?.classList.remove(
    'toolsResourceColumnVisible'
  );
  $first('.l3ColumnVisible')?.classList.remove('l3ColumnVisible');
  $first('.l2ItemSelected')?.classList.remove('l2ItemSelected');
  $first('.l1ItemSelected')?.classList.remove('l1ItemSelected');
};
